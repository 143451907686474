import React from "react"
import Layout from "../layouts/index"
import SEO from "../components/seo"
import {Redirect, Router} from "@reach/router"
import ApplicationResults from "../components/section/application-results";

class Results extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            valid: true,
            progress: 0,
            results: {}
        }
    }

    componentDidMount() {
        let lead = this.props.leadId
        if (!lead) {
            this.setState({valid: false})
            return
        }
        this.fetchResults(lead)
    }

    fetchResults(lead) {
        fetch("https://api.loaned.co.uk/lender/lead/progress/" + lead, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        }).then(response => {
            return response.json()
        }).then(response => {
            if (!response.success) {
                this.setState({valid: false})
                return
            }
            this.setState({
                progress: response.progress,
                results: response.results,
            })
            if (response.progress < 100) {
                const self = this
                window.setTimeout(function () {
                    self.fetchResults(lead)
                }, 500)
            }
        })
    }

    render() {
        if (!this.state.valid) {
            return <Redirect to="/search/" noThrow/>
        }
        return (
            <Layout footer="min" location={this.props.location}>
                <SEO title="Results" bodyAttributes={{class: 'quote-page'}}/>
                <ApplicationResults progress={this.state.progress} results={this.state.results}/>
            </Layout>
        )
    }
}

export default Results